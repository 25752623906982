import { render, staticRenderFns } from "./main-sidebar-sub-menu.vue?vue&type=template&id=65811387"
import script from "./main-sidebar-sub-menu.vue?vue&type=script&lang=js"
export * from "./main-sidebar-sub-menu.vue?vue&type=script&lang=js"
import style0 from "./main-sidebar-sub-menu.vue?vue&type=style&index=0&id=65811387&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/prodctyun-yugao-maintenance-manage-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65811387')) {
      api.createRecord('65811387', component.options)
    } else {
      api.reload('65811387', component.options)
    }
    module.hot.accept("./main-sidebar-sub-menu.vue?vue&type=template&id=65811387", function () {
      api.rerender('65811387', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/main-sidebar-sub-menu.vue"
export default component.exports